import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { Body, BodySmall } from '@components/styled/Typography';
import { ContainerRow } from '@components/styled';
const Container = styled(ContainerRow)(({ theme }) => ({
    width: 'auto',
    height: 'auto',
    gap: theme.spacing_sizes.s,
}));
const TradesRatioBadge = ({ percent, tradesCount, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (_jsxs(Container, { children: [_jsx(Body, { children: `${percent}%` }), _jsx(BodySmall, { color: theme.palette.text.secondary, children: `${tradesCount} ${t('plural_variables.trade', { ns: 'common', count: tradesCount })}` })] }));
};
export default TradesRatioBadge;
