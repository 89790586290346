import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RenderIncomeBadgePlain } from '@shared/ui/display/table-renderers/RenderIncomeBadge';
import TableBodyRow from '@shared/ui/display/Table/components/TableBodyRow';
import TableBodyCell from '@shared/ui/display/Table/components/TableBodyCell';
import TableHeaderCell from '@shared/ui/display/Table/components/TableHeaderCell';
import TableHeaderRow from '@shared/ui/display/Table/components/TableHeaderRow';
import tableRendererWrapper from '@shared/ui/display/Table/tableRendererWrapper';
import TradingPairNameBadge from '@components/portfolio/common/TradingPairNameBadge';
import Table from '@shared/ui/display/Table';
import TradesRatioBadge from '../TradesRatioBadge';
const TableHeaderCellStyled = styled((TableHeaderCell))((props) => ({
    '&.MuiTableCell-root': {
        borderBottom: 'none',
        p: {
            color: props.theme.customColors.input.borderHover,
            fontSize: 12,
        },
    },
}));
const TableHeaderRowStyled = styled((TableHeaderRow))(() => ({
    height: 30,
}));
const TableBodyCellStyled = styled((TableBodyCell))(() => ({
    '&.MuiTableCell-root': {
        borderBottom: 'none',
    },
}));
const TableBodyRowStyled = styled((TableBodyRow))((props) => ({
    height: 40,
    '&:hover': {
        background: props.theme.customColors.shades.primaryLight,
    },
}));
const getColumnsDefinition = (t, colors) => ([
    {
        header: t('portfolio.trading_pair'),
        accessorKey: 'name',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: (props) => {
            const name = props.getValue();
            return (_jsx(TradingPairNameBadge, { name: name, color: colors[name] }));
        },
    },
    {
        header: t('portfolio.ratio'),
        enableSorting: false,
        accessorKey: 'tradesCount',
        meta: {
            flex: 1,
        },
        cell: (props) => (_jsx(TradesRatioBadge, { percent: props.row.original.percentage, tradesCount: props.row.original.tradesCount })),
    },
    {
        header: t('portfolio.pnl'),
        accessorKey: 'pnl',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: tableRendererWrapper(RenderIncomeBadgePlain),
    },
]);
const TopAssetsTable = ({ data, colors, onRowHover, }) => {
    const { t } = useTranslation();
    const columnsDefinition = useMemo(() => getColumnsDefinition(t, colors), [t, colors]);
    return (_jsx(Table, { data: data, rowsCount: data.length, columnDef: columnsDefinition, onBodyRowHover: onRowHover, components: {
            HeaderCell: TableHeaderCellStyled,
            HeaderRow: TableHeaderRowStyled,
            BodyRow: TableBodyRowStyled,
            BodyCell: TableBodyCellStyled,
        } }));
};
export default TopAssetsTable;
